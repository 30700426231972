import { useMemo } from 'react';
import { IMenuItem } from 'src/types/menu';
import { endpoints, fetcher } from 'src/utils/axios';
import useSWR from 'swr';

export function useGetMenu() {
  const URL = endpoints.menu(2);

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      menuItems: (data?.menuItems as IMenuItem[]) || [],
      menuItemsLoading: isLoading,
      menuItemsError: error,
      menuItemsValidating: isValidating,
      menuItemsEmpty: !isLoading && !data?.menuItems?.length,
    }),
    [data?.menuItems, error, isLoading, isValidating]
  );

  return memoizedValue;
}
