import { memo } from 'react';
// @mui
import Stack from '@mui/material/Stack';
// theme
import { hideScroll } from 'src/theme/css';
//
import Iconify from 'src/components/iconify';
import { Typography } from '@mui/material';
import { NavSectionProps, NavListProps, NavConfigProps } from '../types';
import { navHorizontalConfig } from '../config';
import NavList from './nav-list';

// ----------------------------------------------------------------------

function NavSectionHorizontal({ data, config, sx, ...other }: NavSectionProps) {
  return (
    <Stack
      direction="row"
      sx={{
        mx: 'auto',
        ...hideScroll.y,
        ...sx,
      }}
      {...other}
    >
      {data.map((group, index) => (
        <Group
          key={group.subheader || index}
          items={group.items}
          config={navHorizontalConfig(config)}
        />
      ))}
      
      <Stack
        direction="row"
        alignItems='center'
        gap={1}
      >
        <Iconify
          icon="mynaui:clock-five-solid"
          width={16}
          sx={{ flexShrink: 0, ml: 0.5 }}
          color='rgba(99, 115, 129, 1)'
        />

        <Typography
          fontSize={14}
          fontWeight={700}
          lineHeight='24px'
          sx={{
            color: 'rgba(99, 115, 129, 1)',
          }}
        >
          Пн. – Пт.: с 9:00 до 18:00
        </Typography>
      </Stack>
    </Stack>
  );
}

export default memo(NavSectionHorizontal);

// ----------------------------------------------------------------------

type GroupProps = {
  items: NavListProps[];
  config: NavConfigProps;
};

function Group({ items, config }: GroupProps) {
  return (
    <>
      {items.map((list) => (
        <NavList
          key={list.title + list.path}
          data={list}
          depth={1}
          hasChild={!!list.children}
          config={config}
        />
      ))}
    </>
  );
}
