import { Navigate, useRoutes } from 'react-router-dom';
// layouts
// config
import { PATH_AFTER_LOGIN } from 'src/config-global';
//
import Welcome from 'src/pages/welcome';
import { componentsRoutes } from './components';
import { dashboardRoutes } from './dashboard';
import { mainRoutes } from './main';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/welcome',
      element: <Welcome />,
    },
    {
      path: '/',
      element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    },
    ...dashboardRoutes,
    ...mainRoutes,
    ...componentsRoutes,
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);
}
