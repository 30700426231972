import { InputAdornment, TextField } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import Iconify from 'src/components/iconify';
import { ISearchProps } from '../interfaces';

export const Search = (props: ISearchProps) => {
  const { placeholder, getValue } = props;

  const [value, setValue] = useState<string>('');

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value: currentValue } = event.target;

    setValue(currentValue);
    getValue?.(currentValue);
  };

  return (
    <TextField
      value={value}
      onChange={onChangeHandler}
      placeholder={placeholder || 'Я ищу'}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
          </InputAdornment>
        ),
      }}
      size="medium"
      fullWidth
    />
  );
};
