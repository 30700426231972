import { alpha, Button, CircularProgress, Stack, Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { delay } from 'framer-motion';
import { useCallback, useEffect, useState } from 'react';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomPopover from 'src/components/custom-popover';
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
import { bgGradient } from 'src/theme/css';
import Iconify from 'src/components/iconify';

export const WelcomeView = () => {
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);

  const theme = useTheme();
  const router = useRouter();

  const handleModalState = () => {
    setIsModalOpened((prev) => !prev);
  };

  const acceptPolicy = () => {
    handleModalState();
    router.push(paths.dashboard.root);
  };

  const redirectHandler = useCallback(() => {
    delay(() => {
      handleModalState();
    }, 2000);
  }, []);

  useEffect(() => {
    redirectHandler();
  }, [redirectHandler]);

  return (
    <Stack
      flexGrow={1}
      alignItems="center"
      justifyContent="center"
      spacing={10}
      component="main"
      sx={{
        minHeight: '100vh',
        ...bgGradient({
          color: alpha(
            theme.palette.background.default,
            theme.palette.mode === 'light' ? 0.88 : 0.94
          ),
          imgUrl: '/assets/background/overlay_2.jpg',
        }),
      }}
    >
      <Typography
        variant="h3"
        sx={{ maxWidth: 644, textAlign: 'center' }}
        fontSize={{
          xs: 16,
          sm: 32,
        }}
        fontWeight={700}
        lineHeight={{
          xs: '24px',
          sm: '48px',
        }}
      >
        Добро пожаловать в мир инноваций, где идеи превращаются в реальность.
      </Typography>

      <Box
        component="img"
        alt="auth"
        src="/assets/illustrations/welcome.png"
        sx={{ maxWidth: 720, width: '100%', aspectRatio: '21/9' }}
      />

      <Button
        variant="contained"
        color="primary"
        size="large"
        sx={{
          padding: '11px 0',
          width: '100%',
          maxWidth: 214,
          fontSize: 14,
          fontWeight: 700,
          lineHeight: '24px',
          gap: 1,
        }}
        endIcon={
          <CircularProgress
            size={24}
            style={{
              color: '#fff',
            }}
          />
        }
      >
        Загрузка...
      </Button>

      <ConfirmDialog
        open={isModalOpened}
        title={
          <Stack flexDirection="row" gap={1} alignItems="center">
            <Iconify icon="mdi:clipboard-outline" width={16} />

            <Typography fontWeight={700}>Соглашение</Typography>
          </Stack>
        }
        maxWidth="lg"
        content="Сибельмаш использует файлы cookie для различных целей
          Мы хотим, чтобы вы оптимально использовали наш сайт, а также постоянно совершенствовали его. Кроме того, мы можем показывать вам контент и рекламу в зависимости от использования сайта, и для этого мы сотрудничаем с выбранными партнерами (Google). Вы также получаете рекламу на других сайтах через этих партнеров.
          Вы можете отозвать свое добровольное согласие в любое время. Дополнительную информацию (в том числе о передаче данных) и параметры настройки вы можете найти в разделе «Настройки» и в нашем заявлении о конфиденциальности.
        "
        action={
          <Button
            variant="contained"
            color="primary"
            size="medium"
            sx={{
              fontSize: 14,
              fontWeight: 700,
              lineHeight: '24px',
            }}
            onClick={acceptPolicy}
          >
            Принимаю
          </Button>
        }
        onClose={handleModalState}
      />
    </Stack>
  );
};
