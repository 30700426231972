import { forwardRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box, { BoxProps } from '@mui/material/Box';
// routes
import { RouterLink } from 'src/routes/components';
import { paths } from 'src/routes/paths';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    const theme = useTheme();

    const PRIMARY_LIGHT = theme.palette.primary.light;

    const PRIMARY_MAIN = theme.palette.primary.main;

    const PRIMARY_DARK = theme.palette.primary.dark;

    // OR using local (public folder)
    // -------------------------------------------------------
    // const logo = (
    //   <Box
    //     component="img"
    //     src="/logo/logo_single.svg" => your path
    //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
    //   />
    // );

    const logo = (
      <Box
        ref={ref}
        component="div"
        sx={{
          // width: 40,
          height: 40,
          display: 'inline-flex',
          ...sx,
        }}
        {...other}
      >
        <svg
          width="173"
          height="40"
          viewBox="0 0 173 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_2103_60308)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1.64746 17.9567L10.3289 2.87061H6.96232L0 14.9615L1.64746 17.9567ZM16.8184 19.3012L19.0915 15.3586H23.7855L25.9965 19.239L23.6614 23.2917H19.0915L16.8184 19.3012ZM26.1684 27.8467L31.0009 19.4543L26.1684 10.9711H16.4842L11.6516 19.3634L16.4842 27.8467H26.1684ZM41.1102 18.1003L42.8627 15.0572L35.9338 2.87061H32.4335L41.1102 18.1003ZM14.2303 39.9998H28.5035L30.1557 37.129H12.5971L14.2303 39.9998Z"
              fill={PRIMARY_DARK}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1.17969 22.9234L8.11813 35.1053H21.9902L24.884 30.0861H21.5843L20.4431 32.067H9.71307L4.54147 22.9904L6.1364 20.2249L11.3414 29.3684H14.6793L6.12208 14.3445L1.17969 22.9234ZM30.5427 13.8373H33.0975L38.3025 22.9761L33.0688 32.067H29.8694L35.1079 22.9665L33.3888 19.9474L24.6644 35.1053H34.7545L41.7073 23.0335L34.8357 10.9713H28.9096L30.5427 13.8373ZM10.3291 17.0478L12.0004 14.1483L10.8209 12.0861L16.1262 2.87081H26.8515L28.3891 5.56938H17.7785L16.026 8.60766H33.4891L28.5849 0H14.3785L12.0482 4.08134L7.46392 12.0144L10.3291 17.0478Z"
              fill={PRIMARY_MAIN}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M78.7293 14.024H81.7616V16.2201H78.7293V14.024ZM78.7293 9.30148H81.7616V11.4929H78.7293V9.30148ZM75.697 6.43066V19.0862H83.2754L84.7891 17.5694V14.024L83.6144 12.6747L84.7891 11.4929V7.94741L83.2754 6.43066H75.697ZM122.351 14.024H125.384V16.2201H122.351V14.024ZM119.319 19.0862H126.936L128.43 17.5886V12.823L126.735 11.1579H122.351V9.30148H128.43V6.43066H119.319V19.0862ZM107.868 6.43066V19.0862H110.9L114.606 11.8182V19.0862H117.466V6.43066H114.606L110.9 13.7034V6.43066H107.868ZM130.101 6.43066V19.0862H133.134L136.839 11.8182V19.0862H139.705V6.43066H136.839L133.134 13.7034V6.43066H130.101ZM89.6742 9.30148H92.7065V16.2201H89.6742V9.30148ZM86.6419 7.94741V17.5694L88.1604 19.0862H94.0531L95.5716 17.5694V7.94741L94.0531 6.43066H88.1604L86.6419 7.94741ZM67.7797 9.30148H70.9791V16.2201H67.7797V9.30148ZM64.9145 7.94741L64.9336 17.5886L66.433 19.0862H72.3257L73.8442 17.5886V7.94741L72.3257 6.43066H66.433L64.9145 7.94741ZM53.9648 6.43066V19.0862H56.9971V14.1962H60.0294V19.0862H63.0617V6.43066H60.0294V11.3254H56.9971V6.43066H53.9648ZM144.585 9.30148H147.617V12.5072H144.585V9.30148ZM141.557 6.43066V19.0862H144.585V15.3732H149.136L150.649 13.8565V7.94741L149.136 6.43066H141.557ZM162.611 6.43066V19.0862H165.644V14.1962H166.99L169.516 19.0862H172.549L169.349 12.5072L172.382 6.43066H169.464L166.923 11.3254H165.644V6.43066H162.611ZM152.001 7.94741V17.5694L153.515 19.0862H159.613L161.112 17.5886V15.0383H158.061V16.2201H155.028V9.30148H158.061V10.4833H161.112V7.94741L159.598 6.43066H153.515L152.001 7.94741ZM97.4244 7.9187V17.5886L99.2868 19.0766H105.031L106.521 17.5886V15.0383H103.489V16.2201H100.457V9.30148H103.489V10.4833H106.521V7.94741L105.003 6.43066H98.9382L97.4244 7.9187Z"
              fill={PRIMARY_DARK}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M122.017 34.11H135.321V21.4497H132.293V31.2392H130.101V21.4497H127.069V31.2392H124.882V21.4497H122.017V34.11ZM96.2449 21.4497V34.11H99.2772V26.6602L101.297 30.732H103.656L105.848 26.6602V34.11H108.708V21.4497H105.848L102.553 27.3301L99.2772 21.4497H96.2449ZM113.761 26.0095L115.193 24.3205H116.793V27.1913H113.761V26.0095ZM64.7474 34.11H73.5052V31.2392H67.6125V29.2153H72.8319V26.3444H67.6125V24.3205H73.5052V21.4497H64.7474V34.11ZM89.0009 28.8755H91.866V31.2392H89.0009V28.8755ZM75.0237 25.5023V34.11H77.8841V26.0095L79.3167 24.3205H81.0835V34.11H83.9487V21.4497H78.4523L75.0237 25.5023ZM53.9648 22.9712V32.5885L55.4834 34.11H61.5479L63.0617 32.5885V30.0574H60.0294V31.2392H56.9971V24.3205H60.0294V25.5023H63.0617V22.9712L61.5479 21.4497H55.4834L53.9648 22.9712ZM85.9686 21.4497V34.11H93.3798L94.8983 32.5885V27.5263L93.3798 26.0095H89.0009V21.4497H85.9686ZM110.733 25.5023V34.11H113.761V30.0574H116.793V34.11H119.825V21.4497H114.162L110.733 25.5023Z"
              fill={PRIMARY_MAIN}
            />
          </g>
          <defs>
            <clipPath id="clip0_2103_60308">
              <rect width="172.549" height="40" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </Box>
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} href={paths.dashboard.root} sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

export default Logo;
