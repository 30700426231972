import { Box } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { WelcomeView } from 'src/sections/welcome';
// components

// ----------------------------------------------------------------------

export default function Welcome() {
  return (
    <Box>
      <Helmet>
        <title>Welcome</title>
      </Helmet>

      <WelcomeView />
    </Box>
  );
}
